
import {displayCivilRecordsList} from '../../../auto/js/lists/CivilRecordList'
import {displayNewTranslationsForm} from '../../../auto/js/forms/metadata/TranslationsForm'
import {displayTranslationsList} from '../../../auto/js/lists/metadata/TranslationsList'
import {whoami} from '../../../auto/js/users/UserInfo';
import { displayNewElectionForm } from '../forms/NewElectionForm'
import { displayElectionList } from '../lists/ElectionList'
import { displayPollingCenterList } from '../lists/metadata/PollingCenterList';
import { displayNewPollingCenterForm } from '../forms/metadata/PollingCenterForm';
import { displayVoterRecordsWithoutAddress } from '../lists/VoterRecordListWithoutAddress';
import { displayPollingunitnameList } from '../../../auto/js/lists/metadata/PollingunitnameList';
import { displayNewPollingunitnameForm } from '../../../auto/js/forms/metadata/PollingunitnameForm';

export const menu = () => {
    return {
        ...((whoami().roles.includes('ALL')|| whoami().roles.includes('REGISTRAR')) && {
            "books": {
				submenu: {
					"books": {
						options: {
							search: { label: "Voter Records", do: () => displayCivilRecordsList() },
                            noAddress: { label: "Voter Records Without Address", do: () => displayVoterRecordsWithoutAddress()},
						},
						label: "Voter Records"
					}
				},
				label: "Books"
			}
        }),
        ...((whoami().roles.includes('ALL')|| whoami().roles.includes('REGISTRAR')) && {
            "elections": {
                submenu: {
                    "election": {
                        options: {
                            new: {label: "New", do: () => displayNewElectionForm()},
                            applications: { label: "Elections", do: displayElectionList}
                        },
                        label: "Election"
                    }
                },
                label: "Election"
            }
        }),
        ...((whoami().roles.includes('ALL') || whoami().roles.includes('MAINTENANCE')) && {
            "crvs-admin": {
                submenu: {
                    "translations": {
                        options: {
                            list: {label: "Translations", do: displayTranslationsList},
                            new: {label: "New", do: displayNewTranslationsForm},
                        },
                        label: "Translations"
                    },
                },
                label: "Configuration"
            }
        }),
        ...((whoami().roles.includes('ALL') || whoami().roles.includes('MAINTENANCE')) && {
            "geography": {
                submenu: {
                    "locations": {
                        options: {
                            list: {label: "Geo Data", do: displayPollingunitnameList},
                            new: {label: "New", do: displayNewPollingunitnameForm},
                        },
                        label: "Geo Data"
                    },
                    "polling-center": {
                        options: {
                            list: {label: "Polling Centers", do: displayPollingCenterList},
                            new: {label: "New", do: displayNewPollingCenterForm},
                        },
                        label: "Polling Center"
                    }

                },
                label: "Geography"
            }

        })
    }
}
