import React from "react";
import _ from "lodash";
import { getServiceUri, pojoMetadata } from "../../../auto/js/metadata";
import { rest, t } from "../../../auto/js/services";
import { showNotification } from "../../../auto/js/utils";
import { ELECTION_CHANGE_EVENT } from "../events/Event";
import { loadElectionInfo } from "../election/ElectionInfo";

export const electionFields = [
	{name: "name", type: "text", x:1, y:3, layout: "col-md-6"},
	{name: "electionDate", type: "date", x:1, y: 6, layout:"col-md-6"}
];

export const saveElectionForm = async (formData) => {
	let data = _.clone(formData);
    let dto = pojoMetadata['election'].form2dto(data);
    try {
		return rest.request(getServiceUri() + 'apply/create-election', 'POST', dto).then(response => {
			let filter = {};
			filter['key'] = 'election';
			filter['value'] = response;
			loadElectionInfo(response);
			rest.setPreferences(filter).then(() => ELECTION_CHANGE_EVENT.publish(response));
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Created New Election`, "success");
		})
    } catch (err) {
        alert(err);
    }
}

export const buildElectionFormEmptyObject = (fields) => {
	const empty = {};
	for (let i = 0; i < fields.length; i++) {
		let field = fields[i];
		switch (field.type) {
			case ("text"):
				empty[field.name] = "";
				break;
			case ("number"):
				empty[field.name] = "";
				break;
			case ("checkbox"):
				empty[field.name] = false;
				break;
			case ("timestampz"):
				empty[field.name] = '';
				break;
			case ("date"):
				empty[field.name] = null;
				break;
			case ("select"): // dynamic lists, loaded from the backend
				empty[field.name] = '';
				break;
			case ("list"): // static lists, hardcoded
				empty[field.name] = '';
				break;
			case ("password"):
				empty[field.name] = '';
				break;
		}
	}
	return empty;
}