import React from "react";
import {v4 as uuidv4} from 'uuid';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PrintIcon from '@material-ui/icons/Print';

import { OPEN_VIEW_EVENT, PRINT_EVENT } from "../../../../auto/js/events/Gui";
import { rest, t } from "../../../../auto/js/services";
import { createTableComponent } from "../../../../auto/js/widgets/TableComponent";
import { displayReadPollingCenterForm } from "../../forms/metadata/PollingCenterForm";
import { getServiceUri } from "../../../../auto/js/metadata";
import { showNotification } from "../../../../auto/js/utils";
import { POLLING_CENTER_ORDER_BY_NAME } from "../../../../auto/js/metadata/PollingCenterOrderBy";
import { VoterList } from "../../../../auto/js/lists/VoterList";

const fields = [
    {title: "ID", field: "id"},
    {title: "name", field: "name"},
];

export const displayPollingCenterList = () => {
    let PollingCenterList = createTableComponent(fields);
    let uuid = uuidv4();
	OPEN_VIEW_EVENT.publish({
		uuid, view: () => <PollingCenterList key={uuid} loadData={async (query) => buildData(query)} actions={getTableActions()} editable={getTableEditable()}/>
	});
}

const buildData = async (query) => {
    let filter = query;
    let data;
    filter["polling-center"] = {};
    filter["orderBy"] = POLLING_CENTER_ORDER_BY_NAME ;
    filter.orderDirection = null;
    filter.offset = query.page * query.pageSize;
    if (query.search && query.search!='') {
        fields.forEach(element => {
            filter["polling-center"][element.field] = query.search;
        });
        filter["and"] = false;
    }        
    return await getPollingCenterData(filter).then(response => {
        data = response;
        return countData(filter).then((count) => {return {data: data, totalCount: count, page: query.page}})
        });
}

const countData = async (filter) => {
    return await rest.count("polling-center", filter);
}

const getPollingCenterData = async (filter) => {
    return await rest.search("polling-center", filter);
}

const getTableEditable = () => {
    let editables = {};
    editables.onRowDelete = rowData =>
    new Promise((resolve, reject) => {
        try {
            return rest.delete('polling-center', rowData.id).then((response) =>
            {
                if (response.status != 200 || response.status != 201 || response.status != 204) {
                    showNotification(response.message?.split('Detail: ')[1], "error");
                    reject();
                }
                else {
                    showNotification(t`Deleted Polling Center`, "success");
                }
            })
        } catch (err) {
            alert(err);
            reject();
        }
    }).then(() => displayPollingCenterList());
    return editables;
}

const getTableActions = () => {
    let actions = [];
    actions.push(
        {
            icon: () => <VisibilityIcon/>,
                tooltip: t`Edit`,
                onClick: (event, rowData) => {
                    displayReadPollingCenterForm(rowData.id);
                }
        }
    )
    actions.push(
        {
            icon: () => <PrintIcon/>,
            tooltip: t`Print`,
            onClick: (event, rowData) => {
                 openVoterRollPrintDialog(rowData.name, rowData.id);
            }
        }
    )
    return actions;   
}

const openVoterRollPrintDialog = (pollingCenterName, pollingCenterId) => {
    loadVoterList(pollingCenterId).then((voterList) => {
        addImages(voterList).then(list => {
            const printable = {
                content:<VoterList list={list} pollingCenterName={pollingCenterName}/>,
                copyParentStyle:true
            }
            PRINT_EVENT.publish(printable);
        })
    });
}

const loadVoterList = async (pollingCenterId) => {
	return await rest.request(getServiceUri() + "custom/get-vital-record-list-by-polling-station-code/" + pollingCenterId, 'GET');
}

const addImages = async (DefaultRows) => {
    const newRows = [];
    for (let i in DefaultRows) {
        let row = DefaultRows[i];
        if (row.faceId != null && row.faceId != 0) {
            await rest.loadImage(getServiceUri() + 'face/image/' + row.faceId).then((image) => {
                var objectURL = URL.createObjectURL(image);
                row.image = (objectURL != null) ? objectURL : '/public/avatar.png';                  
            })
		} else
            row.image = "/public/avatar.png";
      newRows.push(row);
    }
    return newRows;
}