import React, { useState } from "react";
import {v4 as uuidv4} from 'uuid';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { OPEN_VIEW_EVENT } from "../../events/Gui";
import { rest, t } from "../../services";
import {createTableComponent} from "../../widgets/TableComponent";
import {geoDataMetadataLoader} from "../../metadata/GeoDataMetadataLoader";
import { displayReadPollingunitnameForm } from "../../forms/metadata/PollingunitnameForm";
import { showNotification } from "../../utils";
import { SimpleAutoCompleteInput } from "../../widgets/SimpleAutoCompleteInput";
import { pollingCenterMetadataLoader } from "../../metadata/PollingCenterMetadataLoader";

const fields = [
    {title: "country", field: "country"},
    {title: "regionName", field: "regionName"},
    {title: "stateName", field: "stateName"},
    {title: "localGovName", field: "localGovName"},
    {title: "regAreaName", field: "regAreaName"},
    {title: "pollingUnitName", field: "pollingUnitName"},
    {title: "pollingCenter", field: "pollingCenter", 
        editComponent: props => {
            const [value, setValue] = useState(props.value);

            const handleChange = (newValue) => {
		        if (newValue.key) {
                    props.onChange(newValue);
		            setValue(newValue);
		        }
   			}
            return (
                <SimpleAutoCompleteInput 
                    name={"pollingCenter"} 
                    options={() => pollingCenterMetadataLoader.getRootNodes()} 
                    handleChange={(data) => handleChange(data)}  
                    defaultValue={value}
                    disabled={false}/>

            )
        },
        render: rowData => <>{(rowData.pollingCenter && rowData.pollingCenter != null)?rowData.pollingCenter.value:""}</>
    }
];

export const displayPollingunitnameList = () => {
    let LocationsList = createTableComponent(fields);
    let uuid = uuidv4();
    OPEN_VIEW_EVENT.publish({
        uuid, view: () => <LocationsList key={uuid} localData={true} loadData={geoDataMetadataLoader.getPollingunitnameList()} editable={getTableEditable()} actions={getTableActions()} />
    });
}

const getTableEditable = () => {
    let editables = {};
    editables.onRowDelete = rowData =>
    new Promise((resolve, reject) => {
        try {
            let dto = form2dto(rowData);
            return rest.update('geo-data', dto).then((response) =>
            {
                if (response.status) {
                    showNotification(response.message?.split('Detail: ')[1], "error");
                    reject();
                }
                else {
                    showNotification(t`Deleted Pollingunitname`, "success");
                    geoDataMetadataLoader.load().then(() => resolve());
                }
            })
        } catch (err) {
            alert(err);
            reject();
        }
    }).then(() => displayPollingunitnameList());
    editables.onRowUpdate = (newData, oldData) =>
        new Promise((resolve, reject) => {
            let dto = form2dto(newData);
            dto.disabled = false;
            dto["pollingCenterId"] = (newData.pollingCenter && newData.pollingCenter != null)?newData.pollingCenter.key:null;
            try {
                return rest.update('geo-data', dto).then((response) => {
                    if (response.status) {
                        showNotification(response.message?.split('Detail: ')[1], "error");
                        reject();
                    }
                    else {
                        showNotification(t`Linked Location to Polling Center`, "success");
                        geoDataMetadataLoader.load().then(() => resolve());
                    }
                })
            } catch (err) {
                alert(err);
            }
        }).then(() => displayPollingunitnameList());
    return editables;
}

const getTableActions = () => {
    let actions = [];
    actions.push(
        {
        icon: () => <VisibilityIcon/>,
        tooltip: t`Edit`,
        onClick: (event, rowData) => {
            displayReadPollingunitnameForm({
                "country": {key: rowData.countryId, value: rowData.country},
                "regionName": {key: rowData.regionNameId, value: rowData.regionName},
                "stateName": {key: rowData.stateNameId, value: rowData.stateName},
                "localGovName": {key: rowData.localGovNameId, value: rowData.localGovName},
                "regAreaName": {key: rowData.regAreaNameId, value: rowData.regAreaName},
                "pollingunitname": rowData.pollingunitName,
                "id": rowData.pollingUnitNameId,
                "areaId": rowData.areaId
            }) 
        }
        }
    );
    return actions;
}

const form2dto = (data) => {
	let updatedata = {};
	updatedata.id = ~~(data.pollingUnitNameId);
	updatedata.areaId = data.areaId;
	updatedata.name = data.pollingunitName;
	updatedata.parentId = ~~(data.regAreaNameId);
	updatedata.type = 6;
	if(updatedata.areaId == "")
		updatedata.areaId = null;
	if(updatedata.name == "")
		updatedata.name = null;
    updatedata.disabled = true;
	return updatedata
}
