import React, { useEffect, useState } from 'react';
import {GUI_LANGUAGE_CHANGE_EVENT} from '../../../auto/js/events/Gui';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { rest, t } from '../../../auto/js/services';
import { ELECTION_CHANGE_EVENT } from '../events/Event';
import { loadElectionInfo } from '../election/ElectionInfo';
import { loadingIcon } from '../../../auto/js/loadingIcon';

export function   ElectionSelector () {

    const [election, setElection] = useState(undefined);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [elections, setElections] = useState(undefined)
    const open = Boolean(anchorEl);

    if (!elections) {
      let filter = {};
      filter["election"] = {}
      rest.search('election', filter).then(response => {
          setElections(response);
      });
      return loadingIcon;
    }

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleChange = (el) => {
      let filter = {};
      filter['key'] = 'election';
      filter['value'] = el;
      loadElectionInfo(el);
      rest.setPreferences(filter)
      .then(
        () => {
            ELECTION_CHANGE_EVENT.publish(el)
            setElection(el);
            setAnchorEl(null);
        })
    };

	const buildMenu = () => {
		let items = [];
		elections.forEach(l => {
			items.push(<MenuItem onClick={() => handleChange(l.id)} key={l.id}>{l.name}</MenuItem>);
		});
		return items;
	}
	
   return(
        <>
        <Button
          id="basic-button"
          aria-controls="basic-menu"
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          >
            {t`Select Election`}
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          >
		   {buildMenu()}
        </Menu>
        </>
    )
}