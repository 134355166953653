import React, { useState } from "react";
import {v4 as uuidv4} from 'uuid';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { OPEN_VIEW_EVENT } from "../../../auto/js/events/Gui";
import { getServiceUri, pojoMetadata } from "../../../auto/js/metadata";
import { rest, t } from "../../../auto/js/services";
import { createTableComponent } from "../../../auto/js/widgets/TableComponent";
import { electionColumns, getStatusList } from "../../../main/js/lists/ElectionListCommon";
import Facets from "../../../auto/js/widgets/Facets";
import { Checkbox, Divider, FormControlLabel } from "@mui/material";
import { ELECTION_ORDER_BY_NAME } from "../../../auto/js/metadata/ElectionOrderBy";
import { displayReadElectionForm } from "../forms/ElectionForm";
import { MTLB_STATUS_IMPORTING, MTLB_STATUS_READY } from "../../../auto/js/metadata/MtlbStatus";

const ElectionPage = ({uuid}) => {
    const [importing, setImporting] = useState(false);
    const [ready, setReady] = useState(false);
    const[selectAllStatus, setSelectAllStatus] = useState(false);

    let ElectionList = createTableComponent(electionColumns);

    const onFacetChange = (key, value) => {
        switch(key) {
            
            case ("importing"):
                setImporting(value.target.checked);
                break;
            case ("ready"):
                setReady(value.target.checked);
                break;
            case ("selectAllStatus"):
            	setSelectAllStatus(value.target.checked);
            	setImporting(value.target.checked);
            	setReady(value.target.checked);
				break;
        }
    }

    const buildData = async (query) => {
        let filter = query;
        filter["orderBy"] = ELECTION_ORDER_BY_NAME;
        filter.orderDirection = null;
        filter.offset = query.page * query.pageSize;
        filter["election"] = {}
        let mtlbStatusList = [];
        if (importing)
            mtlbStatusList.push(MTLB_STATUS_IMPORTING);
        if (ready)
            mtlbStatusList.push(MTLB_STATUS_READY);
        if (!importing && !ready)
            mtlbStatusList = [MTLB_STATUS_IMPORTING, MTLB_STATUS_READY]

            filter["election"] = {statusList: mtlbStatusList};
            filter['and'] = true;
        if (query.search && query.search!='') {
            pojoMetadata["election"].columns.forEach(element => {
                if(element.type=='text'){
                    filter["election"][element.key]= query.search;
                }
            });
            filter["and"] = false;
            filter.fuzziness = "AUTO";
        }
        return await getElectionData(filter).then(response => {
            return filterData(response).then((data) => {
                return countData(filter).then((count) => {return {data: data, totalCount: count, page: query.page}})
            })
        });
    }

    const getElectionData = async (filter) => {
        return await rest.request(getServiceUri() + "election/search-by-status/", "POST", filter);
    }
    
    const countData = async (filter) => {
        return await rest.request(getServiceUri() + "election/count/search-by-status/", "POST", filter);
    }

    const buildFacets = (key, value, label, handleChange) => {
        return (
            <>
            <FormControlLabel
            control={<Checkbox
              checked={value}
              onChange={(event) => handleChange(key, event)}
              color="primary"
              value={value}
            />
            }
            label={label}
            style={{width: '100%'}}
            />
            </>
        )
    }

    return (
        <div>
            <Facets title={t`Voter List Status`} facets={[{key: "selectAllStatus", value: selectAllStatus, separator: true}, { key: "importing", value: importing }, { key: "ready", value: ready }]} facetsComponents={(key, value) => buildFacets(key, value, t(key), onFacetChange)} />
            <ElectionList key={uuid} loadData={async (query) => buildData(query)}  actions={getTableActions()} />
        </div>
    )
}

export const displayElectionList = () => {
    let uuid = uuidv4();
	OPEN_VIEW_EVENT.publish({
		uuid, view: () =>  <ElectionPage uuid={uuid} />
	});
}


const filterData = async (DefaultRows) => {
    const newRows = [];
    for (let i in DefaultRows) {
        let row = DefaultRows[i];
        let date = row.electionDate
        if ( date !== null)
            row.electionDate = date[2] + '-' + date[1] + '-' + date[0]; 
        
        newRows.push(row);
    }
    return newRows;
}

const getTableActions = () => {
    let actions = [];
    actions.push(
        {
            icon: () => <VisibilityIcon/>,
                tooltip: t`View`,
                onClick: (event, rowData) => {
                    displayReadElectionForm(rowData.id);
                }
        }
    )
    return actions;
}