import * as React from 'react';
import { useField } from "formik";
import { Observable } from '../../../auto/js/events/Observable';
import { geoDataMetadataLoader } from '../../../auto/js/metadata/GeoDataMetadataLoader';
import { t } from '../../../auto/js/services';
import { SimpleAutoCompleteInput } from '../../../auto/js/widgets/SimpleAutoCompleteInput';

const countryObservable = new Observable();
const resetRegionnameEvent = new Observable();
const regionNameObservable = new Observable();
const resetStatenameEvent = new Observable();
const stateNameObservable = new Observable();
const resetLocalgovnameEvent = new Observable();
const localGovNameObservable = new Observable();
const resetRegareanameEvent = new Observable();
const regAreaNameObservable = new Observable();
const resetPollingunitnameEvent = new Observable();

let resetList =[
resetRegionnameEvent,
resetStatenameEvent,
resetLocalgovnameEvent,
resetRegareanameEvent,
resetPollingunitnameEvent,
];
export function GeoDataComponent({ ...props }) {
    const [field, meta, helpers] = useField(props.name);
    const { value } = meta;
    const { setValue } = helpers;
    const [hideRegionname, setHideRegionname] = React.useState(true);
    const [hideStatename, setHideStatename] = React.useState(true);
    const [hideLocalgovname, setHideLocalgovname] = React.useState(true);
    const [hideRegareaname, setHideRegareaname] = React.useState(true);
    const [hidePollingunitname, setHidePollingunitname] = React.useState(true);
    let countryValue;
    const handleCountryChange = (value) => {
        if (value.key) {
            countryObservable.publish(geoDataMetadataLoader.getChilds(value.key))
            if (Object.keys(geoDataMetadataLoader.getChilds(value.key)).length)
                setHideRegionname(false);
            else
                setHideRegionname(true);
            setValue(geoDataMetadataLoader.getAreaId(value.key));
        }
        else {
            countryObservable.publish([])
            setHideRegionname(true);
        }
        resetFieldsFromIndex(0);
    }
    let regionNameValue;
    const handleRegionnameChange = (value) => {
        if (value.key) {
            regionNameObservable.publish(geoDataMetadataLoader.getChilds(value.key))
            if (Object.keys(geoDataMetadataLoader.getChilds(value.key)).length)
                setHideStatename(false);
            else
                setHideStatename(true);
            setValue(geoDataMetadataLoader.getAreaId(value.key));
        }
        else {
            regionNameObservable.publish([])
            setHideStatename(true);
        }
        resetFieldsFromIndex(1);
    }
    let stateNameValue;
    const handleStatenameChange = (value) => {
        if (value.key) {
            stateNameObservable.publish(geoDataMetadataLoader.getChilds(value.key))
            if (Object.keys(geoDataMetadataLoader.getChilds(value.key)).length)
                setHideLocalgovname(false);
            else
                setHideLocalgovname(true);
            setValue(geoDataMetadataLoader.getAreaId(value.key));
        }
        else {
            stateNameObservable.publish([])
            setHideLocalgovname(true);
        }
        resetFieldsFromIndex(2);
    }
    let localGovNameValue;
    const handleLocalgovnameChange = (value) => {
        if (value.key) {
            localGovNameObservable.publish(geoDataMetadataLoader.getChilds(value.key))
            if (Object.keys(geoDataMetadataLoader.getChilds(value.key)).length)
                setHideRegareaname(false);
            else
                setHideRegareaname(true);
            setValue(geoDataMetadataLoader.getAreaId(value.key));
        }
        else {
            localGovNameObservable.publish([])
            setHideRegareaname(true);
        }
        resetFieldsFromIndex(3);
    }
    let regAreaNameValue;
    const handleRegareanameChange = (value) => {
        if (value.key) {
            regAreaNameObservable.publish(geoDataMetadataLoader.getChilds(value.key))
            if (Object.keys(geoDataMetadataLoader.getChilds(value.key)).length)
                setHidePollingunitname(false);
            else
                setHidePollingunitname(true);
            setValue(geoDataMetadataLoader.getAreaId(value.key));
        }
        else {
            regAreaNameObservable.publish([])
            setHidePollingunitname(true);
        }
        resetFieldsFromIndex(4);
    }
    let pollingUnitNameValue;
    const handlePollingunitnameChange = (value) => {
        if (value.key) {
            setValue(geoDataMetadataLoader.getAreaId(value.key));
        }
    }
    const showData = (value) => {
	    let addressComponents = value.split(".")
	    if (addressComponents[0]) {
	        let country = geoDataMetadataLoader.getArea(addressComponents[0]);
	        if (country) {
	        	countryValue = { key: country.id, value: country.name };
		        countryObservable.publish(geoDataMetadataLoader.getGeoLevel(country.id, 1))
		        if (hideRegionname)
		        	setHideRegionname(false);
	        }
	    }
	    if (addressComponents[1]) {
	        let regionName = geoDataMetadataLoader.getArea(addressComponents[0] + "." + addressComponents[1]);
	        if (regionName) {
	        	regionNameValue = { key: regionName.id, value: regionName.name };
		        regionNameObservable.publish(geoDataMetadataLoader.getGeoLevel(regionName.id, 2))
		        if (hideStatename)
		        	setHideStatename(false);
	        }
	    }
	    if (addressComponents[2]) {
	        let stateName = geoDataMetadataLoader.getArea(addressComponents[0] + "." + addressComponents[1] + "." + addressComponents[2]);
	        if (stateName) {
	        	stateNameValue = { key: stateName.id, value: stateName.name };
		        stateNameObservable.publish(geoDataMetadataLoader.getGeoLevel(stateName.id, 3))
		        if (hideLocalgovname)
		        	setHideLocalgovname(false);
	        }
	    }
	    if (addressComponents[3]) {
	        let localGovName = geoDataMetadataLoader.getArea(addressComponents[0] + "." + addressComponents[1] + "." + addressComponents[2] + "." + addressComponents[3]);
	        if (localGovName) {
	        	localGovNameValue = { key: localGovName.id, value: localGovName.name };
		        localGovNameObservable.publish(geoDataMetadataLoader.getGeoLevel(localGovName.id, 4))
		        if (hideRegareaname)
		        	setHideRegareaname(false);
	        }
	    }
	    if (addressComponents[4]) {
	        let regAreaName = geoDataMetadataLoader.getArea(addressComponents[0] + "." + addressComponents[1] + "." + addressComponents[2] + "." + addressComponents[3] + "." + addressComponents[4]);
	        if (regAreaName) {
	        	regAreaNameValue = { key: regAreaName.id, value: regAreaName.name };
		        regAreaNameObservable.publish(geoDataMetadataLoader.getGeoLevel(regAreaName.id, 5))
		        if (hidePollingunitname)
		        	setHidePollingunitname(false);
	        }
	    }
	    if (addressComponents[5]) {
	        let pollingUnitName = geoDataMetadataLoader.getArea(addressComponents[0] + "." + addressComponents[1] + "." + addressComponents[2] + "." + addressComponents[3] + "." + addressComponents[4] + "." + addressComponents[5]);
	        if (pollingUnitName) {
	        	pollingUnitNameValue = { key: pollingUnitName.id, value: pollingUnitName.name };
	        }
	    }
	}

    const resetFieldsFromIndex = (index) => {
        for (let i = index; i < resetList.length; i++)
            resetList[i].publish()
    }
    
    if (value != null)
        showData(value);
    let style = (props.linear)?"row":"";
    return (
        <>
            <div class="mainform">
                <section class={"fullwidth " + style}>
                    	<SimpleAutoCompleteInput layout={(props.linear)?"2":""} name={"country"} options={() => geoDataMetadataLoader.getRootNodes()} label={t`country`} handleChange={(data) => handleCountryChange(data)}  defaultValue={countryValue} disabled={props.disabled}/>
                    	{(!hideRegionname ||regionNameValue) && <SimpleAutoCompleteInput layout={(props.linear)?"2":""} name={"regionName"} options={() => ""} label={t`regionName`} handleChange={(data) => handleRegionnameChange(data)} observable={countryObservable} reset={resetRegionnameEvent} defaultValue={regionNameValue} disabled={props.disabled}/>}
                    	{(!hideStatename ||stateNameValue) && <SimpleAutoCompleteInput layout={(props.linear)?"2":""} name={"stateName"} options={() => ""} label={t`stateName`} handleChange={(data) => handleStatenameChange(data)} observable={regionNameObservable} reset={resetStatenameEvent} defaultValue={stateNameValue} disabled={props.disabled}/>}
                    	{(!hideLocalgovname ||localGovNameValue) && <SimpleAutoCompleteInput layout={(props.linear)?"2":""} name={"localGovName"} options={() => ""} label={t`localGovName`} handleChange={(data) => handleLocalgovnameChange(data)} observable={stateNameObservable} reset={resetLocalgovnameEvent} defaultValue={localGovNameValue} disabled={props.disabled}/>}
                    	{(!hideRegareaname ||regAreaNameValue) && <SimpleAutoCompleteInput layout={(props.linear)?"2":""} name={"regAreaName"} options={() => ""} label={t`regAreaName`} handleChange={(data) => handleRegareanameChange(data)} observable={localGovNameObservable} reset={resetRegareanameEvent} defaultValue={regAreaNameValue} disabled={props.disabled}/>}
                    	{(!hidePollingunitname ||pollingUnitNameValue) && <SimpleAutoCompleteInput layout={(props.linear)?"2":""} name={"pollingUnitName"} options={() => ""} label={t`pollingUnitName`} handleChange={(data) => handlePollingunitnameChange(data)} observable={regAreaNameObservable} reset={resetPollingunitnameEvent} defaultValue={pollingUnitNameValue} disabled={props.disabled}/>}
                </section>
            </div>
        </>
    )
}
