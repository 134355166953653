import React from "react";


import * as Yup from 'yup';

import '../pages/printable.css';
import { Section } from "../utils";
import { GeoDataComponent } from "../../../auto/js/widgets/GeoDataComponent";

export const civilRecordFormfields = [

    { name: "image", type: "image", x: 1, y: 2, layout: "col-md-12" },
    { name: "cardId", type: "text", x: 1, y: 3, layout: "col-md-6" },
    { name: "firstname", type: "text", x: 1, y: 4, layout: "col-md-6",
        "validation": Yup.string().nullable().default(undefined).min(2, 'First name must be at least two characters long').required('First name is required')},
    { name: "secondname", type: "text", x: 2, y: 4, layout: "col-md-6" },
    { name: "thirdname", type: "text", x: 1, y: 5, layout: "col-md-6" },
    { name: "fourthname", type: "text", x: 2, y: 5, layout: "col-md-6",
        "validation": Yup.string().nullable().default(undefined).min(2, 'Last name must be at least two characters long').required('Last name is required')},
    {name: "fifthname", type: "text", x:1, y: 6, layout:"col-md-12"},
    {name:"addressBox", label: "Address", components: [
	    {name: "lastAddress", type: "custom", x:1, y:9, layout:"col-md-12", component: (name, disabled) => <GeoDataComponent name={name} disabled={false}/>},
    ], type: "box", x:1, y:9, layout:"col-md-11 ms-2 mt-2 pt-1 pb-2"},
    {name: "birthdate", type: "date", x:1, y:11, layout:"col-md-12"},
];

export const birthCertificateContent =

    {
        leftTitle1: "Republic of Guinea-Bissau",
        leftTitle2: "Civil Registration and Identity Management",
        rightTitle1: "Republic of Guinea-Bissau",
        rightTitle2: "Civil Registration and Identity Management",
        mainTitle: "Guinea-Bissau Birth Registration Certificate",
        mainSubTitle: "",
        signedBy: ""
    };