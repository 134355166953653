import { rest } from "../../../auto/js/services/RestClient";
import { pollingCenterMetadataLoader } from "./PollingCenterMetadataLoader";

class GeoDataMetadataLoader {

    constructor() {
        if (!!GeoDataMetadataLoader.instance) {
            return GeoDataMetadataLoader.instance;
		}
		GeoDataMetadataLoader.instance = this;
		this.areas= {};
		this.children = {};
		this.locationsdata = [];
		this.maxAreaId = 0;
		return this;
	}

	load = async () => {
		let filter = {};
		filter['geo-data'] = {disabled: false};
		filter.orderBy = null;
        this.areas= {};
		this.rootAreaIdList = [];
		return rest.search('geo-data', filter)
			.then(response => {
				response.forEach(element => {
					this.areas[element.areaId] = { "name": element.name, "id": element.id, "pollingCenterId": element.pollingCenterId };
					if (element.type < 7 && !this.children[element.areaId] ) {
						this.children[element.areaId] = [];
					}
					if (element.type == 6) {
						this.rootAreaIdList.push(element.areaId);
						if (parseInt(element.areaId) > this.maxAreaId)
							this.maxAreaId = parseInt(element.areaId)
					}
					if (!this.isRootNode(element.areaId)) {
						let parentareaid = this.getParentAreaId(element.areaId);
						this.children[parentareaid]? this.children[parentareaid].push(element.areaId) :
						this.children[parentareaid] = [element.areaId];
					}
				});
			})
			.then(()=> {
			this.locationsdata = [];
			this.preparelocationsdata();
			});
	}

	preparelocationsdata = () => {
		var eldetails = [];
		const getLocationsFlat = (ellist, eldetail) => {
			ellist.forEach(el => {
			var elname = eldetail + "#" + this.areas[el].name + "::" + this.areas[el].id;
			if (Array.isArray(this.children[el]) && (this.children[el].length)) {
				getLocationsFlat(this.children[el], elname);
			}
			else {
				eldetails.push({"Hierarchy":elname, "Areaid": el, "pollingCenterId": this.areas[el].pollingCenterId});
			}
			});
			return eldetails;
		}
		this.rootAreaIdList.forEach(element => {
			if (this.children[element].length)
				eldetails = getLocationsFlat(this.children[element],
					this.areas[element].name + "::" + this.areas[element].id)
		});
		
		eldetails.forEach(el => {
			const ArrayData = el.Hierarchy.split("#");
			this.locationsdata.push({
			    "country": ArrayData[0]?.split("::")[0],
			    "countryId": ArrayData[0]?.split("::")[1],
			    "regionName": ArrayData[1]?.split("::")[0],
			    "regionNameId": ArrayData[1]?.split("::")[1],
			    "stateName": ArrayData[2]?.split("::")[0],
			    "stateNameId": ArrayData[2]?.split("::")[1],
			    "localGovName": ArrayData[3]?.split("::")[0],
			    "localGovNameId": ArrayData[3]?.split("::")[1],
			    "regAreaName": ArrayData[4]?.split("::")[0],
			    "regAreaNameId": ArrayData[4]?.split("::")[1],
			    "pollingUnitName": ArrayData[5]?.split("::")[0],
			    "pollingUnitNameId": ArrayData[5]?.split("::")[1],
			    "areaId": el.Areaid,
				"pollingCenterId": el.pollingCenterId
			})
		});
	}

	getParentAreaId = (areaId) => {
		return areaId.substring(0, areaId.lastIndexOf("."))
	}

	isRootNode = (areaId) => {
		return areaId.lastIndexOf(".") === -1;
	}

	getRootNodes = () => {
  		let areaids = Object.keys(this.areas).filter(key => key.lastIndexOf(".") === -1);
	    let result = Object.fromEntries(areaids.map(x => [this.areas[x].id, this.areas[x].name]));
	    return result;
	}

	getChilds = (id) => {
		let areaid = Object.keys(this.areas).find(key => this.areas[key].id == id);
		let result = Object.fromEntries(this.children[areaid].map(x => [this.areas[x].id, this.areas[x].name]));
		return result;
	}
	
    getAreaId = (id) => {
        return Object.keys(this.areas).find(key => this.areas[key].id == id);
    }
    
    getChildsByAreaId = (areaId) => {
		if (this.children[areaId])
			return Object.fromEntries(this.children[areaId].map(x => [this.areas[x].id, this.areas[x].name]));
		else
			return []
	}
    
    getArea = (areaId) => {
        return this.areas[areaId];
    }
    
    getAreaLevelName = (areaId) => {
		let areaComponents = areaId.split(".");
		switch (areaComponents.length) {
			case (1):
				return "country";
			case (2):
				return "regionName";
			case (3):
				return "stateName";
			case (4):
				return "localGovName";
			case (5):
				return "regAreaName";
			case (6):
				return "pollingUnitName";
		}
	}
	
	getLevelChildsByIndex = (index) => {
		let areaids = Object.keys(this.areas).filter(key => key.lastIndexOf(".") === index);
	    let result = Object.fromEntries(areaids.map(x => [this.areas[x].id, this.areas[x].name]));
	    return result;
	}
	getCountryList = () => {
		let countryList = [];
		let areaids = Object.keys(this.areas).filter(key => key.split(".").length === 1);
		areaids.forEach(element => {
			let country = this.getArea(element);
			countryList.push({
			    "country": country.name,
			    "countryId": country.id,
			    "areaId": element,
			})
		});
		return countryList;
	}
	getRegionnameList = () => {
		let regionNameList = [];
		let areaids = Object.keys(this.areas).filter(key => key.split(".").length === 2);
		areaids.forEach(element => {
			let regionName = this.getArea(element);
			let areaComponents = element.split(".");
			let countryAreaId = areaComponents[0];
			let country = this.getArea(countryAreaId);
			regionNameList.push({
				"country": (country)?country.name:"",
			    "countryId": (country)?country.id:"",
			    "regionName": regionName.name,
			    "regionNameId": regionName.id,
			    "areaId": element,
			})
		});
		return regionNameList;
	}
	getStatenameList = () => {
		let stateNameList = [];
		let areaids = Object.keys(this.areas).filter(key => key.split(".").length === 3);
		areaids.forEach(element => {
			let stateName = this.getArea(element);
			let areaComponents = element.split(".");
			let countryAreaId = areaComponents[0];
			let country = this.getArea(countryAreaId);
			let regionNameAreaId = areaComponents[0] + "." + areaComponents[1];
			let regionName = this.getArea(regionNameAreaId);
			stateNameList.push({
				"country": (country)?country.name:"",
			    "countryId": (country)?country.id:"",
				"regionName": (regionName)?regionName.name:"",
			    "regionNameId": (regionName)?regionName.id:"",
			    "stateName": stateName.name,
			    "stateNameId": stateName.id,
			    "areaId": element,
			})
		});
		return stateNameList;
	}
	getLocalgovnameList = () => {
		let localGovNameList = [];
		let areaids = Object.keys(this.areas).filter(key => key.split(".").length === 4);
		areaids.forEach(element => {
			let localGovName = this.getArea(element);
			let areaComponents = element.split(".");
			let countryAreaId = areaComponents[0];
			let country = this.getArea(countryAreaId);
			let regionNameAreaId = areaComponents[0] + "." + areaComponents[1];
			let regionName = this.getArea(regionNameAreaId);
			let stateNameAreaId = areaComponents[0] + "." + areaComponents[1] + "." + areaComponents[2];
			let stateName = this.getArea(stateNameAreaId);
			localGovNameList.push({
				"country": (country)?country.name:"",
			    "countryId": (country)?country.id:"",
				"regionName": (regionName)?regionName.name:"",
			    "regionNameId": (regionName)?regionName.id:"",
				"stateName": (stateName)?stateName.name:"",
			    "stateNameId": (stateName)?stateName.id:"",
			    "localGovName": localGovName.name,
			    "localGovNameId": localGovName.id,
			    "areaId": element,
			})
		});
		return localGovNameList;
	}
	getRegareanameList = () => {
		let regAreaNameList = [];
		let areaids = Object.keys(this.areas).filter(key => key.split(".").length === 5);
		areaids.forEach(element => {
			let regAreaName = this.getArea(element);
			let areaComponents = element.split(".");
			let countryAreaId = areaComponents[0];
			let country = this.getArea(countryAreaId);
			let regionNameAreaId = areaComponents[0] + "." + areaComponents[1];
			let regionName = this.getArea(regionNameAreaId);
			let stateNameAreaId = areaComponents[0] + "." + areaComponents[1] + "." + areaComponents[2];
			let stateName = this.getArea(stateNameAreaId);
			let localGovNameAreaId = areaComponents[0] + "." + areaComponents[1] + "." + areaComponents[2] + "." + areaComponents[3];
			let localGovName = this.getArea(localGovNameAreaId);
			regAreaNameList.push({
				"country": (country)?country.name:"",
			    "countryId": (country)?country.id:"",
				"regionName": (regionName)?regionName.name:"",
			    "regionNameId": (regionName)?regionName.id:"",
				"stateName": (stateName)?stateName.name:"",
			    "stateNameId": (stateName)?stateName.id:"",
				"localGovName": (localGovName)?localGovName.name:"",
			    "localGovNameId": (localGovName)?localGovName.id:"",
			    "regAreaName": regAreaName.name,
			    "regAreaNameId": regAreaName.id,
			    "areaId": element,
			})
		});
		return regAreaNameList;
	}
	getPollingunitnameList = () => {
		let pollingUnitNameList = [];
		let areaids = Object.keys(this.areas).filter(key => key.split(".").length === 6);
		areaids.forEach(element => {
			let pollingUnitName = this.getArea(element);
			let areaComponents = element.split(".");
			let countryAreaId = areaComponents[0];
			let country = this.getArea(countryAreaId);
			let regionNameAreaId = areaComponents[0] + "." + areaComponents[1];
			let regionName = this.getArea(regionNameAreaId);
			let stateNameAreaId = areaComponents[0] + "." + areaComponents[1] + "." + areaComponents[2];
			let stateName = this.getArea(stateNameAreaId);
			let localGovNameAreaId = areaComponents[0] + "." + areaComponents[1] + "." + areaComponents[2] + "." + areaComponents[3];
			let localGovName = this.getArea(localGovNameAreaId);
			let regAreaNameAreaId = areaComponents[0] + "." + areaComponents[1] + "." + areaComponents[2] + "." + areaComponents[3] + "." + areaComponents[4];
			let regAreaName = this.getArea(regAreaNameAreaId);
			pollingUnitNameList.push({
				"country": (country)?country.name:"",
			    "countryId": (country)?country.id:"",
				"regionName": (regionName)?regionName.name:"",
			    "regionNameId": (regionName)?regionName.id:"",
				"stateName": (stateName)?stateName.name:"",
			    "stateNameId": (stateName)?stateName.id:"",
				"localGovName": (localGovName)?localGovName.name:"",
			    "localGovNameId": (localGovName)?localGovName.id:"",
				"regAreaName": (regAreaName)?regAreaName.name:"",
			    "regAreaNameId": (regAreaName)?regAreaName.id:"",
			    "pollingUnitName": pollingUnitName.name,
			    "pollingUnitNameId": pollingUnitName.id,
			    "areaId": element,
				"pollingCenter": (this.areas[element].pollingCenterId && this.areas[element].pollingCenterId != null)? {key: this.areas[element].pollingCenterId, value: pollingCenterMetadataLoader.getArea(pollingCenterMetadataLoader.getAreaId(this.areas[element].pollingCenterId)).name}:null
			})
		});
		return pollingUnitNameList;
	}
    
    getGeoLevel = (id, level) => {
		let areaId = Object.keys(this.areas).find(key => this.areas[key].id == id);
		let areaIds = Object.keys(this.areas).filter(key => key.split(".").length === level + 1 && key.startsWith(areaId))
		let result = Object.fromEntries(areaIds.map(x => [this.areas[x].id, this.areas[x].name]));
	    return result; 
	}
	
	getNewAreaId = () => {
		this.maxAreaId = this.maxAreaId + 1;
		return this.maxAreaId;
	}

	incrementAreaIdFromParentId = (id) => {
		let areaid = Object.keys(this.areas).find(key => this.areas[key].id == id);
		let nextAreaId = `${areaid}.0`;
		if (this.children[areaid].length) {
			let biggerAreaId = Math.max(...this.children[areaid].map(x => x.split(".").slice(-1)[0]));
			nextAreaId = `${areaid}.${biggerAreaId + 1}`;
		}
		return nextAreaId;
	}

}

export const geoDataMetadataLoader = new GeoDataMetadataLoader();
