import React, {useEffect} from "react";
import {v4 as uuidv4} from 'uuid';
import { CAN_I_SWITCH, OPEN_VIEW_EVENT } from "../../../../auto/js/events/Gui";
import { rest, t } from "../../../../auto/js/services";
import { showNotification } from "../../../../auto/js/utils";
import { AlertDialog } from "../../../../auto/js/widgets/Dialogs";
import { createFormComponent } from "../../../../auto/js/widgets/FormComponent";
import {geoDataMetadataLoader} from "../../metadata/GeoDataMetadataLoader";
import {Observable} from "../../../../auto/js/events/Observable";
const countryObservable = new Observable();

const handleCountryChange = (value) => {
    countryObservable.publish(geoDataMetadataLoader.getChilds(value.key))
}
const regionnameObservable = new Observable();

const handleRegionnameChange = (value) => {
    regionnameObservable.publish(geoDataMetadataLoader.getChilds(value.key))
}
const statenameObservable = new Observable();

const handleStatenameChange = (value) => {
    statenameObservable.publish(geoDataMetadataLoader.getChilds(value.key))
}
const localgovnameObservable = new Observable();

const handleLocalgovnameChange = (value) => {
    localgovnameObservable.publish(geoDataMetadataLoader.getChilds(value.key))
}
const regareanameObservable = new Observable();

const handleRegareanameChange = (value) => {
    regareanameObservable.publish(geoDataMetadataLoader.getChilds(value.key))
}
const fields = [
    {
    name:"country",
    type:"autocomplete",
    x:1, y:0, layout:"col-md-6",
        metadata: () => geoDataMetadataLoader.getRootNodes(),
        
        handleChange:(data) => handleCountryChange(data),   
    },
    {
    name:"regionname",
    type:"autocomplete",
    x:1, y:1, layout:"col-md-6",
        metadata: () => "",
        observable: countryObservable,
        handleChange:(data) => handleRegionnameChange(data),   
    },
    {
    name:"statename",
    type:"autocomplete",
    x:1, y:2, layout:"col-md-6",
        metadata: () => "",
        observable: regionnameObservable,
        handleChange:(data) => handleStatenameChange(data),   
    },
    {
    name:"localgovname",
    type:"autocomplete",
    x:1, y:3, layout:"col-md-6",
        metadata: () => "",
        observable: statenameObservable,
        handleChange:(data) => handleLocalgovnameChange(data),   
    },
    {
    name:"regareaname",
    type:"autocomplete",
    x:1, y:4, layout:"col-md-6",
        metadata: () => "",
        observable: localgovnameObservable,
        handleChange:(data) => handleRegareanameChange(data),   
    },
    {
    name:"pollingunitname",
    type:"text",
    x:1, y:5, layout:"col-md-6",
    },
];

let PollingunitnameForm = createFormComponent(fields);

class FormComponent extends React.Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        CAN_I_SWITCH.pickUpThePhone(this.listen);
        this.state = {
        closeRequested: undefined
        }
    }
    componentDidMount() {
    this.props.loadData().then(
    	rowdata => {
        	countryObservable.publish(geoDataMetadataLoader.getChilds(rowdata?.country?.key));
        	regionnameObservable.publish(geoDataMetadataLoader.getChilds(rowdata?.regionname?.key));
        	statenameObservable.publish(geoDataMetadataLoader.getChilds(rowdata?.statename?.key));
        	localgovnameObservable.publish(geoDataMetadataLoader.getChilds(rowdata?.localgovname?.key));
        	regareanameObservable.publish(geoDataMetadataLoader.getChilds(rowdata?.regareaname?.key));
 		}
    )}
    listen = (closeMe) => {
        if (!this.isDirty())
            closeMe(true);
        this.setState({closeRequested: closeMe})
    }

    isDirty = () => {
        return this.myRef.current.isDirty();
    }

    handleDialogCancel = () => {
        this.state.closeRequested(false);
        this.setState({closeRequested: undefined});
    }

    handleSave = () => {
        this.myRef.current.save().then(() => {
        this.state.closeRequested(true);
    });
    }

    handleDontSave = () => {
        this.state.closeRequested(true);
    }

    render() {
        return (
        <>
        <AlertDialog
                title={t`Save your changes ?`}
                open={(this.state.closeRequested && this.isDirty())?true:false}
                handleClose={this.handleDialogCancel}
                noAgree={true}
                save={this.handleSave}
                dontSave={this.handleDontSave}
        />
        <PollingunitnameForm ref={this.myRef} key={this.props.key} loadData={() => this.props.loadData()} onSubmit={this.props.onSubmit} id={this.props.id} buttons={getButtons} />
        </>
        )
    }

}

export const displayNewPollingunitnameForm = () => {
    let uuid = uuidv4();
    OPEN_VIEW_EVENT.publish({
        uuid, view: () => <FormComponent key={uuid} loadData={async () => buildEmptyObject(fields)} onSubmit={save}/>
    });
}

export const displayReadPollingunitnameForm = (rowdata) => {
    let uuid = uuidv4();
    OPEN_VIEW_EVENT.publish({
        uuid, view: () => <FormComponent key={uuid} loadData={() => Promise.resolve(rowdata)} onSubmit={update}
        id={rowdata.pollingunitnameId}/>
    });
}

const save = async (formData) => {
    let dto = form2dto(formData);
    dto.id = null;
    dto.areaId = geoDataMetadataLoader.incrementAreaIdFromParentId(dto.parentId);
    try {
        return rest.create('geo-data', dto).then((response) =>{
        if (response.status)
            showNotification(response.message.split('Detail: ')[1], "error")
        else {
            showNotification(t`Created Pollingunitname`, "success");
            geoDataMetadataLoader.load();
        }
        });
    } catch (err) {
    alert(err);
    }
}

const update = async (formData) => {
    let dto = form2dto(formData);
    try {
        return rest.update('geo-data', dto).then((response) =>{
            if (response.status)
                showNotification(response.message.split('Detail: ')[1], "error")
            else {
                showNotification(t`Updated Pollingunitname`, "success");
                geoDataMetadataLoader.load();
            }
        });
    } catch (err) {
    alert(err);
    }
}


const form2dto = (data) => {
    let updatedata = {};
    updatedata.id = ~~(data.id);
    updatedata.areaId = data.areaId;
    updatedata.name = data.pollingunitname;
    updatedata.type = 6;
    updatedata.parentId = ~~(data.regareaname?.key);
    if(updatedata.areaId == "")
    updatedata.areaId = null;
    if(updatedata.name == "")
    updatedata.name = null;
    updatedata.disabled = false;
    return updatedata
}

const buildEmptyObject = (fields) => {
    const empty = {};
    for (let i = 0; i < fields.length; i++) {
        let field = fields[i];
        switch (field.type) {
        case ("text"):
        empty[field.name] = "";
        break;
        case ("number"):
        empty[field.name] = "";
        break;
        case ("checkbox"):
        empty[field.name] = false;
        break;
        case ("timestampz"):
        empty[field.name] = '';
        break;
        case ("date"):
        empty[field.name] = null;
        break;
        case ("select"): // dynamic lists, loaded from the backend
        empty[field.name] = '';
        break;
        case ("list"): // static lists, hardcoded
        empty[field.name] = '';
        break;
        case ("password"):
        empty[field.name] = '';
        break;
        }
    }
    return empty;
}

const getButtons = () => {
    return <button style={{ minWidth: '5rem' }} type="submit"> {t`Submit`} </button>
}